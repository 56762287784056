<template>
 <div class="app-container">
     <brandset @getComInfo="getComInfo" :comData ="comData" ></brandset>
  </div>
</template>
<script>
import * as api from '@/api/companys';
import brandset from '@/components/companys/brandSet';
export default {
    components: {
        brandset
    },
    mounted() {
      this.getComInfo();
    },
    data() {
        return {
            activeName: '1',
            comData: {}
        };
    },
    methods: {
        // 获取公司信息
        getComInfo() {
          api.getComInfo(
            {
                request: {}
            }).then((res) => {
                this.comData = res;
            });
      }
    }
};
</script>
